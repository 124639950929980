/* Slider */
.slick-slider
{
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list
{
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus
{
  outline: none;
}
.slick-list.dragging
{
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track
{
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
  display: table;

  content: '';
}
.slick-track:after
{
  clear: both;
}
.slick-loading .slick-track
{
  visibility: hidden;
}

.slick-slide
{
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide
{
  float: right;
}
.slick-slide img
{
  display: block;
}
.slick-slide.slick-loading img
{
  display: none;
}
.slick-slide.dragging img
{
  pointer-events: none;
}
.slick-initialized .slick-slide
{
  display: block;
}
.slick-loading .slick-slide
{
  visibility: hidden;
}
.slick-vertical .slick-slide
{
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}


.catalog-photo {
  background-color: #131313;
  height: 100vh;
  header {
    background-color: #131313;
    nav a {
      color: #ffffff;
    }
    nav ul li a:after {
      background: #ffffff;
    }
    .logo {
      color: #ffffff;
    }
  }
  h1 {
    color: #ffffff;
  }
  .bread-crumbs .button:last-child span {
    color: #ffffff;
  }
}

.contetn_photo {
  display: flex;
  justify-content: center;
}
.number_slider {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;

  > * {
    color: #FFFFFF;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    margin: 0 3px;
  }
}

.photo_catalog {
  width: 864px;
  height: 489px;
  display: flex;
  justify-content: center;
  .slick-list {
    //height: 76.6vh !important;
  }

}
.photo_catalog.slick-vertical .slick-slide {
  margin-bottom: 170px;
  height: 489px;
}
.photo_catalog .slick-slide {
  //margin-top: 60px;
  transition: .5s;

}
.photo_catalog .slick-slide.next,.photo_catalog .slick-slide.slick-active,.photo_catalog .slick-slide.prev {
   //margin-top: 0;
}
.photo_catalog.slick-vertical .slick-slide.slick-active {
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.photo_catalog img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin: 0 auto;
  position: relative;
}
.photo_catalog_img.smoll_img img{
  width: 340px;
}
.slider_photo {
  position: relative;
  padding-top: 73px;
}
.reviews_dots {
  display: flex;
  position: absolute;
  flex-direction: column;
  height: 160px;
  justify-content: space-between;
  top: calc(50% - 45px);
  right: -95px;
}
.nav_control {
  position: relative;
  width: 18px;
  height: 16px;
  cursor: pointer;
  z-index: 1;
}
.nav_control.nav_control_prev:after {
  transform: rotate(-225deg) ;
  top: 5px;
}
.nav_control:after {
  content: '';
  position: absolute;
  margin-top: 0;
  width: 11px;
  height: 11px;
  right: 0;
  border: 0;
  top: -1px;
  left: 3px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg) ;
}
.js-hover.is-hover  .nav_control:after{
  border-left: 2px solid #000000;
  border-bottom: 2px solid #000000;
  z-index: 5;
}

.js-pointer {
  width: 2.6vw;
  height: 2.6vw;
  position: fixed;
  z-index: 0;
  margin-top: -1.3vw;
  margin-left: -1.3vw;
  border-radius: 100px;
  box-sizing: border-box;
  pointer-events: none;
}
.js-pointer span {
  transition: all 0.2s;
  transform: scale(0);
  background: rgba(255,255,255,1);
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
  box-sizing: border-box;
  border-radius: 300px;
}
.js-pointer.icon span {
  transform: scale(1);
  opacity: 1;
}
.js-pointer svg {
  transition: all 0.3s;
  width: 80%;
  height: 80%;
  position: absolute;
  z-index: 1;
  top: 10%;
  left: 10%;
  transform: scale(1.5);
}
.js-pointer path {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 1px;
  stroke-dasharray: 69px;
  stroke-dashoffset: 69px;
}

.js-hover {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

@media only screen and (max-width: 1367px) {

  .number_slider {
    top: -40px;
  }
  .slider_photo {
    padding-top: 25px;
  }
  .photo_catalog {
    width: 59.586206896551715vw;
    height: calc(100vh - 144px);
    overflow: hidden;
  }
  .photo_catalog.slick-vertical .slick-slide {
    margin-bottom: 30px;
    height: 33.724137931034484vw;
  }
  .photo_catalog_img.smoll_img img {
    width: 24.285714285714285vw;
  }
  .reviews_dots {
    height: 160px;
    top: calc(50% - 16vh);
    right: -95px;
  }
}