.wrapper-breadcrumb {
  margin-top: 3px;
}
.bread-crumbs {
  display: flex;
  align-items: center;
  transition: .5s;
}
.bread-crumbs .button {
  margin-right: 20px;
  position: relative;
}
.bread-crumbs .button:not(:last-child):before {
  content: '/';
  position: absolute;
  margin-right: -13px;
  color: #C4C4C4;
  top: 50%;
  font-size: 10px;
  right: 0;
  transform: rotate(-6deg) translate(0, -50%);
}
.bread-crumbs .button:not(:last-child) a {

}
.bread-crumbs a {
  color: #C4C4C4;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  transition: .5s;
}
.bread-crumbs a:hover {
  color: #7f7f7f;
}
.bread-crumbs .button:last-child span {
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-family: 'bodoni', sans-serif;
  color: #131313;
  font-size: 24px;
  text-transform: capitalize;
  margin-top: 21px;
  font-weight: 400;
}

main {
  padding-top: 77px;
}

.pagination_catalog {
  display: flex;
  justify-content: space-between;
  margin-top: 73px;
  > * {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    position: relative;
    font-size: 12px;
    color: #000000;
  }
  .prev {
    margin-left: 68px;
    width: 39px;
    text-align: left;
  }
  .next {
    margin-right: 68px;
    width: 39px;
    text-align: right;
  }
  .prev:before,.next:before {
    content: '';
    height: 1px;
    width: 100%;
    background-color: #000000;
    position: absolute;
    bottom: -12px;
  }
  .next:before {
    right: 0;
  }
}

@media only screen and (max-width: 1367px) {

  main {
    padding-top: 50px;
  }
}