

.katalog-product {
  display: flex;
  justify-content: space-between;
  margin-top: 47px;
  margin-bottom: 180px;
}

.filter_panel {
  width: 207px;
  padding-top: 10px;
}

.product-katalog {
  width: 1000px;
}

.container_article {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

article:not(:nth-child(-n+3)) {
  margin-top: 84px;
}

article {
  width: 30.33%;
  display: flex;
  position: relative;
  overflow: hidden;
  transition: .3s;
  a {
    color: #131313;
    padding: 21px 68px 25px;
  }
  h3 {
    font-family: 'Bodoni', "Times New Roman";
    font-weight: 400;
    font-size: 22px;
    text-transform: capitalize;
    color: #131313;

    transition: .3s;
  }
  .top_article {
    display: flex;
    font-family: 'Open Sans', sans-serif;
    color: #000000;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 24px;
    font-weight: 400;
    transition: .3s;
    p {
      position: relative;
      margin-right: 20px;
    }
    p:before {
      content: '/';
      position: absolute;
      right: -11px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12px;
    }
    span {
      color: #C4C4C4;
      transition: .5s;
    }
  }
  .catalog_img {
    width: 170px;
    height: 245px;
    overflow: hidden;
  }
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    transition: transform .4s;
  }
  .buttons-wrap {
    margin-top: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #131313;
    margin-bottom: 11px;
    p {
      position: relative;
      display: inline-block;
    }
    p:before {
      content: '';
      height: 1px;
      width: 100%;
      background-color: #000000;
      position: absolute;
      bottom: -12px;
    }
  }
  .hover {
    background-color: #000000;
    position: absolute;
    top: calc(50% + 12px);
    left: 50%;
    width: 165px;
    height: 240px;
    transform: translate(-50%, -50%);
    z-index: -1;
    opacity: 0;
    transition: width .47s, height .46s, opacity .4s, all .4s ;
    transition-timing-function: ease-in-out;
  }
}
.reset_filter {
  margin-top: 75px;
  button {
    width: 178px;
    height: 43px;
    background: #FFFFFF;
    border: 1px solid #131313;
    color: #131313;
    font-size: 13px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    transition: .5s ease-in-out;
  }
  button:hover {
    background-color: #000000;
    color: #ffffff;
    box-shadow: 0 10px 20px rgba(31, 32, 65, 0.05);
  }
}


article:hover {
  h3 {
    color: #ffffff;
  }
  .buttons-wrap {
    color: #ffffff;
  }
  .buttons-wrap p:before {
    background-color: #ffffff;
  }
  .hover {
    width: 100%;
    height: 100%;
    opacity: 1;
    top: calc(50% + 0px);
  }
  .top_article {
    color: #ffffff;
    span {
      color: #ffffff;
    }
  }
  img {
    transform: scale(1.1);
  }
}

.filter-section {
  .name {
    width: 180px;
    border-bottom: 1px solid #000000;;
    span {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      color: #343434;
      font-size: 14px;
      line-height: 44px;
    }
  }
}
.filter-section:not(:first-child) {
  margin-top: 40px;
}

.filter_toggle {
  margin-top: 19px;
}

.filter_prop {
  margin: 9.3px 0;
  display: flex;
  align-items: center;
  transition: visibility .5s linear, height .5s linear, margin .5s linear, opacity .1s;
  will-change: margin;
  transition-duration: 0.3s;
  position: relative;
  span {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #000000;
    font-size: 13px;
    text-transform: capitalize;
    position: relative;
    padding-left: 36px;
    cursor: pointer;
    transition: .5s;
  }
  span:hover {
    opacity: .8;
  }
  input {
    position: absolute;
    opacity: 0;
  }

  span:before {
    content: '';
    border: 1px solid #131313;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: -5px;
    transition: background .3s ease, border-color .3s ease;
  }
  input:checked ~ span:before {
    background-color: #000000;
  }
}

.filter_prop_toggle {
  display: flex;
  flex-direction: column;
  position: relative;
  //.filter_prop:first-child {
  //  margin: 7.5px 0 9.3px
  //}
  .active_filter.active {
    //margin: 7.5px 0 35px;
  }
  .filter_prop:not(:first-child).active {
    //margin: 19px 0  7.5px;
  }
  .filter_prop:last-child.active {
    //margin: 19px 0 7.5px;
  }

}