
footer {
  height: 180px;
  background-color: #181714;
  .footer_logo {
    color: #ffffff;
    p {
      font-size: 22px;
      line-height: 44px;
      text-align: center;
      letter-spacing: 0.02em;
      font-family: 'bodoni', sans-serif;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    letter-spacing: .01em;
    line-height: 17px;
  }
  a {
    color: #ffffff;
    text-decoration: none;
    transition: .5s;
  }
  a:hover {
    opacity: .8;
  }
}
.footer__columns {
  margin-top: -29px;
}
.footer_address {
  margin-bottom: 28px;
}
.footer_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.fa-instagram,.fa-facebook-square,.fa-twitter {
  line-height: 9px;
  font-size: 10.9376px;
  text-align: center;
  color: #FFFFFF;
}
.footer_icon {
  display: flex;
  justify-content: center;
  a {
    transition: .5s;
    margin: 0 14px;
  }
  a:hover {
    opacity: .8;
  }
}

