@charset "UTF-8";
html {
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
  font-family: 'bodoni', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
}

/**
 * Add the correct display in IE 9-.
 */
article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
  text-decoration: none;
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0;
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**Enable panning and pinch zoom gestures, but disable additional non-standard gestures such as double-tap to zoom. Disabling double-tap to zoom removes the need for browsers to delay the generation of click events when the user taps the screen. */
a, area, button, input, label, select, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* Reset
   ========================================================================== */
h1, h2, h3, h4, h5, h6, p, blockquote, em, img, i, dl, dt, ol, ul, li, video, canvas {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

/** to remove the rounded in IOS*/
textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
}

@font-face {
  font-family: 'bodoni';
  src: url("../fonts/bodoni.eot");
  src: local("☺"), url("../fonts/bodoni.woff") format("woff"), url("../fonts/bodoni.ttf") format("truetype"), url("../fonts/bodoni.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 10px;
  right: 3px;
}

::-webkit-scrollbar-track {
  background-color: #ccc;
  width: 0;
}

::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 0;
  width: 0;
  margin: 0;
}

.wrap {
  max-width: 1290px;
  width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 1367px) {
  .wrap {
    max-width: 1366px;
    padding: 0 50px;
    margin: 0 auto;
  }
}

@font-face {
  font-family: 'bodoni';
  src: url("../fonts/bodoni.eot");
  src: url("../fonts/bodoni.eot?#iefix") format("embedded-opentype"), url("../fonts/bodoni.woff") format("woff"), url("../fonts/bodoni.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.one_section {
  overflow: hidden;
}

.one_section .header {
  background-color: #ffffff00;
}

.one_section .header .logo {
  opacity: 0;
}

.one_section .header .wrap {
  max-width: 100%;
}

.one_section .header nav ul {
  height: 50px;
}

.one_section .header nav ul li {
  margin-right: 163px;
}

.one_section .header nav ul li:last-child {
  margin-right: 59px;
}

.header {
  height: 60px;
  background-color: #ffffff;
  width: 100%;
  position: fixed;
  z-index: 5;
  top: 0;
}

.header .logo {
  display: flex;
  align-items: center;
  font-weight: 800;
}

.header nav ul {
  height: 60px;
  display: flex;
  position: relative;
  align-items: center;
}

.header nav ul li {
  margin-right: 100px;
}

.header nav ul li.active a:after {
  opacity: 1;
  transition-delay: .2s;
  width: 100%;
}

.header nav ul li:last-child {
  margin-right: 37px;
}

.header nav ul li a:after {
  content: '';
  position: absolute;
  background: #000000;
  height: 1px;
  width: 0;
  left: 0;
  top: 20px;
  transition: all .3s;
  transform-origin: center;
  opacity: 1;
  z-index: 9;
  -webkit-backface-visibility: hidden;
}

.header nav ul li:hover a:after {
  opacity: 1;
  transition-delay: .2s;
  width: 100%;
}

.header nav a {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 49px;
  font-weight: 400;
  color: #000000;
  backface-visibility: hidden;
  position: relative;
}

.header.move {
  box-shadow: 0 10px 20px rgba(31, 32, 65, 0.05);
}

.header_content {
  display: flex;
  justify-content: space-between;
}

footer {
  height: 180px;
  background-color: #181714;
}

footer .footer_logo {
  color: #ffffff;
}

footer .footer_logo p {
  font-size: 22px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.02em;
  font-family: 'bodoni', sans-serif;
}

footer p {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  letter-spacing: .01em;
  line-height: 17px;
}

footer a {
  color: #ffffff;
  text-decoration: none;
  transition: .5s;
}

footer a:hover {
  opacity: .8;
}

.footer__columns {
  margin-top: -29px;
}

.footer_address {
  margin-bottom: 28px;
}

.footer_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.fa-instagram, .fa-facebook-square, .fa-twitter {
  line-height: 9px;
  font-size: 10.9376px;
  text-align: center;
  color: #FFFFFF;
}

.footer_icon {
  display: flex;
  justify-content: center;
}

.footer_icon a {
  transition: .5s;
  margin: 0 14px;
}

.footer_icon a:hover {
  opacity: .8;
}

.wrapper-breadcrumb {
  margin-top: 3px;
}

.bread-crumbs {
  display: flex;
  align-items: center;
  transition: .5s;
}

.bread-crumbs .button {
  margin-right: 20px;
  position: relative;
}

.bread-crumbs .button:not(:last-child):before {
  content: '/';
  position: absolute;
  margin-right: -13px;
  color: #C4C4C4;
  top: 50%;
  font-size: 10px;
  right: 0;
  transform: rotate(-6deg) translate(0, -50%);
}

.bread-crumbs a {
  color: #C4C4C4;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  transition: .5s;
}

.bread-crumbs a:hover {
  color: #7f7f7f;
}

.bread-crumbs .button:last-child span {
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-family: 'bodoni', sans-serif;
  color: #131313;
  font-size: 24px;
  text-transform: capitalize;
  margin-top: 21px;
  font-weight: 400;
}

main {
  padding-top: 77px;
}

.pagination_catalog {
  display: flex;
  justify-content: space-between;
  margin-top: 73px;
}

.pagination_catalog > * {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  position: relative;
  font-size: 12px;
  color: #000000;
}

.pagination_catalog .prev {
  margin-left: 68px;
  width: 39px;
  text-align: left;
}

.pagination_catalog .next {
  margin-right: 68px;
  width: 39px;
  text-align: right;
}

.pagination_catalog .prev:before, .pagination_catalog .next:before {
  content: '';
  height: 1px;
  width: 100%;
  background-color: #000000;
  position: absolute;
  bottom: -12px;
}

.pagination_catalog .next:before {
  right: 0;
}

@media only screen and (max-width: 1367px) {
  main {
    padding-top: 50px;
  }
}

.section_1 {
  height: 100vh;
  background-color: #000000;
  overflow: hidden;
}

.iframe video {
  width: 100%;
}

.screen-panel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.one_section .section_2 {
  margin-right: 15px;
}

.one_section .screen-panel_project-header {
  display: block;
  z-index: 1;
}

.screen-panel_project-case {
  display: block;
  z-index: 1;
  background-color: #ffffff;
  overflow: auto;
}

.site-parts {
  z-index: 50;
  position: absolute;
}

.iframe:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #000000;
  opacity: 0.1;
}

.home {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

/********************************/
.preloader.active {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 20;
}

.preloader.active .loader__flap {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
}

.preloader.active .loader__flap--top {
  top: 0;
  height: 45.8%;
  background-color: #ffffff;
  -webkit-transform-origin: top;
  transform-origin: top;
  will-change: transform;
}

.preloader.active .loader__loading {
  top: 45.7%;
  background-color: #000000;
  -webkit-transform-origin: top;
  transform-origin: top;
  will-change: transform;
  height: 1px;
  position: absolute;
  right: 0;
  width: 0;
}

.preloader.active .loader__flap--bottom {
  bottom: 0;
  height: 54.3%;
  background-color: #ffffff;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  will-change: transform;
}

.index {
  overflow: hidden;
}

body.index.loader .section_1 {
  z-index: 30;
}

body.index.loader .iframe img {
  transform: scale(1.1);
}

body.index.loader h1 {
  color: #000000;
}

body.index.loader h1 span {
  color: #000000;
}

body.index.loader .title_index {
  position: relative;
  z-index: 20;
}

body.index.loader .left_home {
  z-index: 20;
}

.nav_category {
  overflow: hidden;
}

.title_index {
  height: 41.6vh;
  width: 100%;
}

.index h1 {
  font-size: 125px;
  color: #ffffff;
  margin-top: 0;
  margin-right: 78px;
  letter-spacing: 2px;
}

.index h1 span {
  opacity: 0;
  padding: 0 20px;
}

.index h1 span:nth-child(1) {
  margin-left: -140px;
}

.right_home {
  width: calc(50vw);
  position: relative;
  overflow: hidden;
}

.right_home .preloader_img {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background-color: #000000;
  z-index: 1;
}

.right_home .iframe {
  position: relative;
  width: 100%;
  height: 100%;
}

.right_home .iframe img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.left_home {
  width: 48vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.bottom_section_1 {
  position: absolute;
  bottom: 30px;
  display: flex;
  justify-content: space-between;
  width: 94%;
  left: 0;
}

.bottom_section_1 .linc_social {
  width: 247px;
}

.bottom_section_1 .linc_social ul {
  display: flex;
  justify-content: space-between;
}

.bottom_section_1 .linc_social ul .liner_hover:after {
  background: #ffffff;
}

.bottom_section_1 .linc_social ul a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
}

.bottom_section_1 .lang a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
}

.bottom_section_1 .lang li {
  margin: 0 0 0 18px;
  position: relative;
}

.bottom_section_1 .lang li:not(:last-child):after {
  content: '/';
  position: absolute;
  right: -12px;
  color: #ffffff;
  top: 3px;
  transform: rotate(-4deg);
  font-size: 11px;
}

.bottom_section_1 .lang ul {
  display: flex;
}

.title_nav {
  width: 100%;
  position: relative;
  margin-top: -4px;
}

.title_nav .line_h1 {
  width: 0;
  top: 9px;
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  right: 0;
  opacity: 0;
  transition: .3s;
}

/*****************************/
.section_2 {
  padding-top: 15px;
}

.section_2 .about_img {
  margin-top: 75px;
  position: relative;
  width: 100%;
}

.section_4 .wrap.content_index {
  margin-bottom: 95px;
}

.nav_category li:not(:nth-child(1)) {
  margin: 22px 0;
}

.nav_category li:nth-child(1) {
  margin: 8px 0 22px 0;
  padding-top: 25px;
}

.nav_category li {
  opacity: 0;
}

.nav_category a {
  font-size: 14px;
  text-transform: capitalize;
  color: #FFFFFF;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.nav_category a.liner_hover:after {
  background-color: #ffffff;
}

body.index .wrap.content_index {
  max-width: 1060px;
}

body.index .js-pointer span {
  background: #000000;
}

body.index .js-pointer path {
  stroke: #000000;
}

body.index .js-pointer.section path {
  stroke: #fff;
}

body.index header {
  background-color: #000000;
  color: #ffffff;
}

body.index header a {
  color: #ffffff;
}

body.index header ul li a:after {
  background: #ffffff;
}

body.index .index_about.right {
  display: flex;
  justify-content: flex-end;
}

body.index .position {
  width: 530px;
}

.product_wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 150px;
}

.product_wrap .pro {
  position: relative;
  width: 25%;
  height: 652px;
}

.product_wrap .pro .pro_img {
  width: 100%;
  height: 100%;
}

.product_wrap .pro .pro_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product_wrap .pro a {
  color: #ffffff;
  text-decoration: none;
}

.product_wrap .pro h3 {
  font-size: 30px;
  font-weight: 400;
  opacity: 0;
}

.product_wrap .pro p {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  margin-top: 15px;
  line-height: 24px;
  opacity: 0;
}

.product_wrap .pro .div_pro {
  position: absolute;
  padding: 44px;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  width: 86%;
  height: 86%;
  transition: opacity .1s ease-out, transform .2s ease-in-out;
}

.product_wrap .pro .read-more {
  position: absolute;
  bottom: 60px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  opacity: 0;
}

.product_wrap .pro .read-more:after {
  content: '';
  height: 1px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  bottom: -12px;
  left: 0;
}

.product_wrap .pro:hover .div_pro {
  opacity: 1;
  background-color: #000000;
  transform: translate(-50%, -50%) scale(1);
}

.product_wrap .pro:hover .div_pro p, .product_wrap .pro:hover .div_pro h3, .product_wrap .pro:hover .div_pro .read-more {
  opacity: 1;
}

@media only screen and (min-width: 1025px) {
  .product_wrap .pro {
    height: 33.958333333333336vw;
  }
  .product_wrap .pro h3 {
    font-size: 1.5625vw;
  }
  .product_wrap .pro p {
    font-size: 0.7291666666666666vw;
    margin-top: 0.78125vw;
    line-height: 1.25vw;
  }
  .product_wrap .pro .div_pro {
    padding: 2.2916666666666665vw;
  }
  .product_wrap .pro .read-more {
    bottom: 3.125vw;
    font-size: 0.625vw;
  }
}

img {
  width: 100%;
}

.index_about {
  margin-top: 74px;
}

.index_about h2 {
  font-family: 'bodoni';
  font-size: 30px;
  color: #131313;
  font-weight: 400;
  margin-bottom: 14px;
}

.index_about data {
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #C4C4C4;
}

.index_about p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #343434;
  margin-top: 14px;
  line-height: 24px;
}

.index_about .read-more {
  margin-top: 33px;
  position: relative;
  display: inline-block;
}

.index_about .read-more a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}

.index_about .read-more:after {
  content: '';
  position: absolute;
  bottom: -17px;
  width: 100%;
  height: 1px;
  background-color: #000000;
}

.com_article {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1250px;
}

.com_article article {
  width: 407px;
  height: 555px;
  justify-content: center;
  align-items: center;
}

.com_article article a {
  padding: 0 68px 25px;
}

.com_article article .catalog_img {
  width: 205px;
  height: 295px;
  margin-top: 36px;
}

.com_article article .buttons-wrap {
  margin-bottom: 22px;
  margin-top: 20px;
}

.com_article article .buttons-wrap p:before {
  bottom: -12px;
}

.com_article article:nth-child(1) {
  position: relative;
}

.com_article article:nth-child(3) {
  position: relative;
}

.news_wrap {
  display: flex;
  margin-top: 129px;
}

.news_wrap .slick-list {
  padding: 50px !important;
}

.news_article {
  width: 356px;
  height: 485px;
  position: relative;
  transition: width .5s;
}

.news_article .news_img {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: .3s ease-in-out;
  left: 0;
  top: 0;
}

.news_article .news_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: .3s;
}

.news_article h3 {
  font-size: 30px;
  font-weight: 400;
  color: #FFFFFF;
  position: relative;
  opacity: 0;
  transition: .0s ease-in-out;
}

.news_article p {
  opacity: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  position: relative;
  color: #ffffff;
  transition: .0s ease-in-out;
  font-size: 14px;
  margin-top: 16px;
  line-height: 24px;
}

.news_article .news_pro {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 7px 33px 7px 15px;
}

.news_article .news_pro:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all .3s ease-in-out, z-index .0s;
  background-color: #131313;
  z-index: -1;
}

.liner_hover {
  display: inline-block;
  position: relative;
}

.liner_hover:after {
  content: '';
  position: absolute;
  background: #000000;
  height: 1px;
  width: 0;
  left: 0;
  top: 20px;
  transition: all .3s;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  opacity: 1;
  z-index: 9;
  -webkit-backface-visibility: hidden;
}

.liner_hover:hover:after {
  opacity: 1;
  transition-delay: .2s;
  width: 100%;
}

.news_article:hover .news_pro {
  z-index: 1;
}

.news_article:hover .news_pro h3, .news_article:hover .news_pro p {
  z-index: 1;
  opacity: 1;
  transition: .3s ease-in-out;
}

.news_article:hover img {
  z-index: 1;
}

.news_article:hover .news_img {
  left: -28px;
  top: -43px;
  width: calc(100% + 51px);
  height: calc(100% + 70px);
}

.news_article:hover .news_pro:before {
  background-color: #131313;
  left: -28px;
  z-index: 1;
  top: -43px;
  width: calc(100% + 51px);
  height: calc(100% + 70px);
  transition: all .3s ease-in-out, z-index .0s;
}

.contact_us {
  display: flex;
  padding-top: 75px;
}

.contact_us #map {
  width: 521px;
  height: 312px;
}

.contact_us .index_about {
  margin-top: 0;
}

.section_6 {
  padding-top: 73px;
  padding-bottom: 240px;
}

.section_6 .adress_us {
  margin-top: 20px;
}

.section_6 .adress_us p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #343434;
  margin-top: 0;
}

.section_6 .email_us {
  margin-top: 48px;
  display: flex;
  align-items: center;
  color: #343434;
}

.section_6 .email_us p {
  margin-top: 0;
  transition: .5s;
  padding-right: 8px;
}

.section_6 .email_us a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #343434;
  font-size: 14px;
  transition: .5s;
}

.section_6 .email_us a:hover {
  color: #000000;
}

.section_6 .tell_us {
  margin-top: 48px;
}

.section_6 .tell_us p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  margin-top: 0;
  padding-right: 10px;
  transition: .5s;
  font-size: 14px;
  margin-bottom: 3px;
}

.section_6 .tell_us a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #343434;
  font-size: 14px;
  transition: .5s;
}

.section_6 .tell_us a:hover {
  color: #000000;
}

@media only screen and (max-width: 1600px) and (min-width: 1025px) {
  body.index .wrap.content_index {
    max-width: 66.25vw;
    padding: 0;
  }
  body.index .position {
    width: 33.125vw;
  }
  .section_2 .about_img {
    margin-top: 3.90625vw;
  }
  .index_about .read-more:after {
    bottom: -0.8854166666666666vw;
  }
  .index_about {
    margin-top: 4.625vw;
  }
  .index_about h2 {
    font-size: 1.875vw;
    margin-bottom: 0.8750000000000001vw;
  }
  .index_about data {
    font-size: 0.75vw;
  }
  .index_about p {
    font-size: 0.8750000000000001vw;
    margin-top: 0.8750000000000001vw;
    line-height: 1.5vw;
  }
  .index_about .read-more {
    margin-top: 2.0625vw;
  }
  .index_about .read-more a {
    font-size: 0.75vw;
  }
  .com_article article {
    width: 25.4375vw;
    height: 34.6875vw;
  }
  .com_article article a {
    padding: 0 4.25vw 1.5625vw;
  }
  .com_article article h3 {
    font-size: 1.575vw;
  }
  .com_article article .top_article {
    font-size: 0.8125vw;
    margin-top: 0.625vw;
    margin-bottom: 1.5vw;
  }
  .com_article article .top_article p {
    margin-right: 1.25vw;
  }
  .com_article article .catalog_img {
    width: 12.812499999999998vw;
    height: 18.4375vw;
    margin-top: 2.25vw;
  }
  .com_article article .buttons-wrap {
    margin-bottom: 1.375vw;
    margin-top: 1.25vw;
  }
  .com_article {
    max-width: 78.125vw;
  }
  article .hover {
    top: calc(50% + 0.75vw);
    left: 50%;
    width: 10.3125vw;
    height: 15vw;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  article:hover .hover {
    width: 100%;
    height: 100%;
    opacity: 1;
    top: calc(50% + 0px);
  }
  .news_wrap {
    margin-top: 8.0625vw;
  }
  .news_wrap .slick-list {
    padding: 3.125vw !important;
  }
  .news_article {
    width: 18.541666666666668vw;
    height: 25.260416666666668vw;
  }
  .news_article .news_pro {
    padding: 0.3645833333333333vw 1.7187500000000002vw 0.3645833333333333vw 0.78125vw;
  }
  .news_article h3 {
    font-size: 1.5625vw;
  }
  .news_article p {
    font-size: 0.7291666666666666vw;
    margin-top: 0.8333333333333334vw;
    line-height: 1.25vw;
  }
  .news_article:hover .news_img {
    left: -1.4583333333333333vw;
    top: -2.2395833333333335vw;
    width: calc(100% + 2.65625vw);
    height: calc(100% + 3.6458333333333335vw);
  }
  .news_article:hover .news_pro:before {
    left: -1.4583333333333333vw;
    top: -2.2395833333333335vw;
    width: calc(100% + 2.65625vw);
    height: calc(100% + 3.6458333333333335vw);
  }
  .section_6 {
    padding-top: 4.5625vw;
    padding-bottom: 15vw;
  }
  .contact_us {
    padding-top: 4.6875vw;
  }
  .section_6 .adress_us {
    margin-top: 1.25vw;
  }
  .contact_us #map {
    width: 32.5625vw;
    height: 19.5vw;
  }
  .section_6 .adress_us p {
    font-size: 0.8750000000000001vw;
  }
  .section_6 .email_us a {
    font-size: 0.8750000000000001vw;
  }
  .section_6 .email_us {
    margin-top: 3vw;
  }
  .section_6 .tell_us {
    margin-top: 3vw;
  }
  .section_6 .tell_us p {
    padding-right: 0.625vw;
    font-size: 0.8750000000000001vw;
    margin-bottom: 0.1875vw;
  }
  .section_6 .tell_us a {
    font-size: 0.8750000000000001vw;
  }
  footer {
    height: 11.25vw;
  }
  .footer__columns {
    margin-top: -1.8124999999999998vw;
  }
  footer .footer_logo p {
    font-size: 1.375vw;
    line-height: 2.75vw;
  }
  .footer_address {
    margin-bottom: 1.7500000000000002vw;
  }
  footer p {
    font-size: 0.8125vw;
    line-height: 1.0625vw;
  }
  /******************************/
  .index h1 {
    font-size: 6.510416666666667vw;
    margin-right: 4.0625vw;
    letter-spacing: 0.10416666666666667vw;
  }
  .index h1 span {
    padding: 0 1.0416666666666665vw;
  }
  .index h1 span:nth-child(1) {
    margin-left: -7.291666666666667vw;
  }
  .one_section .header nav ul li {
    margin-right: 6.489583vw;
  }
  .one_section .header nav ul li:last-child {
    margin-right: 3.0729166666666665vw;
  }
  .one_section header .wrap {
    padding: 0 3.125vw;
  }
  .nav_category a {
    font-size: 0.8750000000000001vw;
  }
  .bottom_section_1 .linc_social {
    width: 15.437500000000002vw;
  }
  .nav_category li:not(:nth-child(1)) {
    margin: 1.375vw 0;
  }
  .nav_category li:nth-child(1) {
    margin: 0.5vw 0 1.375vw 0;
    padding-top: 1.5625vw;
  }
  .bottom_section_1 {
    bottom: 1.875vw;
  }
  .bottom_section_1 .linc_social ul a {
    font-size: 0.8750000000000001vw;
  }
  .bottom_section_1 .lang li {
    margin: 0 0 0 1.125vw;
  }
  .bottom_section_1 .lang a {
    font-size: 0.8750000000000001vw;
  }
  .title_nav .line_h1 {
    top: 0.5625vw;
  }
  .bottom_section_1 .lang li:not(:last-child):after {
    right: -0.75vw;
    top: 0.1875vw;
    font-size: 0.6875vw;
  }
}

.katalog-product {
  display: flex;
  justify-content: space-between;
  margin-top: 47px;
  margin-bottom: 180px;
}

.filter_panel {
  width: 207px;
  padding-top: 10px;
}

.product-katalog {
  width: 1000px;
}

.container_article {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

article:not(:nth-child(-n+3)) {
  margin-top: 84px;
}

article {
  width: 30.33%;
  display: flex;
  position: relative;
  overflow: hidden;
  transition: .3s;
}

article a {
  color: #131313;
  padding: 21px 68px 25px;
}

article h3 {
  font-family: 'Bodoni', "Times New Roman";
  font-weight: 400;
  font-size: 22px;
  text-transform: capitalize;
  color: #131313;
  transition: .3s;
}

article .top_article {
  display: flex;
  font-family: 'Open Sans', sans-serif;
  color: #000000;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 24px;
  font-weight: 400;
  transition: .3s;
}

article .top_article p {
  position: relative;
  margin-right: 20px;
}

article .top_article p:before {
  content: '/';
  position: absolute;
  right: -11px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
}

article .top_article span {
  color: #C4C4C4;
  transition: .5s;
}

article .catalog_img {
  width: 170px;
  height: 245px;
  overflow: hidden;
}

article img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: transform .4s;
}

article .buttons-wrap {
  margin-top: 13px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #131313;
  margin-bottom: 11px;
}

article .buttons-wrap p {
  position: relative;
  display: inline-block;
}

article .buttons-wrap p:before {
  content: '';
  height: 1px;
  width: 100%;
  background-color: #000000;
  position: absolute;
  bottom: -12px;
}

article .hover {
  background-color: #000000;
  position: absolute;
  top: calc(50% + 12px);
  left: 50%;
  width: 165px;
  height: 240px;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;
  transition: width .47s, height .46s, opacity .4s, all .4s;
  transition-timing-function: ease-in-out;
}

.reset_filter {
  margin-top: 75px;
}

.reset_filter button {
  width: 178px;
  height: 43px;
  background: #FFFFFF;
  border: 1px solid #131313;
  color: #131313;
  font-size: 13px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  transition: .5s ease-in-out;
}

.reset_filter button:hover {
  background-color: #000000;
  color: #ffffff;
  box-shadow: 0 10px 20px rgba(31, 32, 65, 0.05);
}

article:hover h3 {
  color: #ffffff;
}

article:hover .buttons-wrap {
  color: #ffffff;
}

article:hover .buttons-wrap p:before {
  background-color: #ffffff;
}

article:hover .hover {
  width: 100%;
  height: 100%;
  opacity: 1;
  top: calc(50% + 0px);
}

article:hover .top_article {
  color: #ffffff;
}

article:hover .top_article span {
  color: #ffffff;
}

article:hover img {
  transform: scale(1.1);
}

.filter-section .name {
  width: 180px;
  border-bottom: 1px solid #000000;
}

.filter-section .name span {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #343434;
  font-size: 14px;
  line-height: 44px;
}

.filter-section:not(:first-child) {
  margin-top: 40px;
}

.filter_toggle {
  margin-top: 19px;
}

.filter_prop {
  margin: 9.3px 0;
  display: flex;
  align-items: center;
  transition: visibility .5s linear, height .5s linear, margin .5s linear, opacity .1s;
  will-change: margin;
  transition-duration: 0.3s;
  position: relative;
}

.filter_prop span {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #000000;
  font-size: 13px;
  text-transform: capitalize;
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  transition: .5s;
}

.filter_prop span:hover {
  opacity: .8;
}

.filter_prop input {
  position: absolute;
  opacity: 0;
}

.filter_prop span:before {
  content: '';
  border: 1px solid #131313;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 0;
  top: -5px;
  transition: background .3s ease, border-color .3s ease;
}

.filter_prop input:checked ~ span:before {
  background-color: #000000;
}

.filter_prop_toggle {
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.catalog-photo {
  background-color: #131313;
  height: 100vh;
}

.catalog-photo header {
  background-color: #131313;
}

.catalog-photo header nav a {
  color: #ffffff;
}

.catalog-photo header nav ul li a:after {
  background: #ffffff;
}

.catalog-photo header .logo {
  color: #ffffff;
}

.catalog-photo h1 {
  color: #ffffff;
}

.catalog-photo .bread-crumbs .button:last-child span {
  color: #ffffff;
}

.contetn_photo {
  display: flex;
  justify-content: center;
}

.number_slider {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

.number_slider > * {
  color: #FFFFFF;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  margin: 0 3px;
}

.photo_catalog {
  width: 864px;
  height: 489px;
  display: flex;
  justify-content: center;
}

.photo_catalog.slick-vertical .slick-slide {
  margin-bottom: 170px;
  height: 489px;
}

.photo_catalog .slick-slide {
  transition: .5s;
}

.photo_catalog.slick-vertical .slick-slide.slick-active {
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.photo_catalog img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin: 0 auto;
  position: relative;
}

.photo_catalog_img.smoll_img img {
  width: 340px;
}

.slider_photo {
  position: relative;
  padding-top: 73px;
}

.reviews_dots {
  display: flex;
  position: absolute;
  flex-direction: column;
  height: 160px;
  justify-content: space-between;
  top: calc(50% - 45px);
  right: -95px;
}

.nav_control {
  position: relative;
  width: 18px;
  height: 16px;
  cursor: pointer;
  z-index: 1;
}

.nav_control.nav_control_prev:after {
  transform: rotate(-225deg);
  top: 5px;
}

.nav_control:after {
  content: '';
  position: absolute;
  margin-top: 0;
  width: 11px;
  height: 11px;
  right: 0;
  border: 0;
  top: -1px;
  left: 3px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
}

.js-hover.is-hover .nav_control:after {
  border-left: 2px solid #000000;
  border-bottom: 2px solid #000000;
  z-index: 5;
}

.js-pointer {
  width: 2.6vw;
  height: 2.6vw;
  position: fixed;
  z-index: 0;
  margin-top: -1.3vw;
  margin-left: -1.3vw;
  border-radius: 100px;
  box-sizing: border-box;
  pointer-events: none;
}

.js-pointer span {
  transition: all 0.2s;
  transform: scale(0);
  background: white;
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
  box-sizing: border-box;
  border-radius: 300px;
}

.js-pointer.icon span {
  transform: scale(1);
  opacity: 1;
}

.js-pointer svg {
  transition: all 0.3s;
  width: 80%;
  height: 80%;
  position: absolute;
  z-index: 1;
  top: 10%;
  left: 10%;
  transform: scale(1.5);
}

.js-pointer path {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 1px;
  stroke-dasharray: 69px;
  stroke-dashoffset: 69px;
}

.js-hover {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

@media only screen and (max-width: 1367px) {
  .number_slider {
    top: -40px;
  }
  .slider_photo {
    padding-top: 25px;
  }
  .photo_catalog {
    width: 59.586206896551715vw;
    height: calc(100vh - 144px);
    overflow: hidden;
  }
  .photo_catalog.slick-vertical .slick-slide {
    margin-bottom: 30px;
    height: 33.724137931034484vw;
  }
  .photo_catalog_img.smoll_img img {
    width: 24.285714285714285vw;
  }
  .reviews_dots {
    height: 160px;
    top: calc(50% - 16vh);
    right: -95px;
  }
}
