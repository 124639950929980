.one_section {
  overflow: hidden;
  .header {
    background-color: #ffffff00;
    .logo {
      opacity: 0;
    }
    .wrap {
      max-width: 100%;
    }
  }
  .header nav ul {
    height: 50px;
  }
  .header nav ul li {
    margin-right: 163px;
  }
  .header nav ul li:last-child {
    margin-right: 59px;
  }
}
.header {
  height: 60px;
  background-color: #ffffff;
  width: 100%;
  position: fixed;
  z-index: 5;
  top: 0;
  .logo {
    display: flex;
    align-items: center;
    font-weight: 800;
  }
  nav ul {
    height: 60px;
    display: flex;
    position: relative;
    align-items: center;
    li {
      margin-right: 100px;
    }
    li.active a:after{
      opacity: 1;
      transition-delay: .2s;
      width: 100%;
    }
    li:last-child {
      margin-right: 37px;
    }
    li a:after {
        content: '';
        position: absolute;
        background: #000000;;
        height: 1px;
        width: 0;
        left: 0;
        top: 20px;
        transition: all .3s;
        transform-origin: center;
        opacity: 1;
        z-index: 9;
        -webkit-backface-visibility: hidden;
      }
    li:hover a:after {
        opacity: 1;
        transition-delay: .2s;
        width: 100%;
      }
  }

  nav a {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 49px;
    font-weight: 400;
    color: #000000;
    backface-visibility: hidden;
    position: relative;
    }

}
.header.move {
  box-shadow: 0 10px 20px rgba(31, 32, 65, 0.05);
}
.header_content {
  display: flex;
  justify-content: space-between;
}
