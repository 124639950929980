// home page styles
.section_1 {
  height: 100vh;
  background-color: #000000;
  overflow: hidden;

}
.iframe {
  video{
    width: 100%;
    //filter: blur(2px);
  }
}
.screen-panel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
//.screen-panel.screen-panel_project-case {
//  position: relative;
//}
.one_section .section_2{
  margin-right: 15px;
}
.one_section .screen-panel_project-header{
  display: block; z-index: 1;
}
//.screen-panel_project-header{
//  display: none;
//}
//.one_section .screen-panel_project-case {
//  display: none;
//}
.screen-panel_project-case {
  display: block; z-index: 1;
  background-color: #ffffff;
  overflow: auto;
}
.site-parts {
  z-index: 50;
  position: absolute;
}


.iframe:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color:#000000;
  opacity: 0.1;
  //filter: invert();

}
.home {
  height: 100%;
  display: flex;
  justify-content: space-between;
}
/********************************/

.preloader.active {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 20;
  .loader__flap {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
  }
  .loader__flap--top {
    top: 0;
    height: 45.8%;
    background-color: #ffffff;
    -webkit-transform-origin: top;
    transform-origin: top;
    will-change: transform;
  }
  .loader__loading {
    top: 45.7%;
    background-color: #000000;
    -webkit-transform-origin: top;
    transform-origin: top;
    will-change: transform;
    height: 1px;
    position: absolute;
    right: 0;
    width: 0;
  }
  .loader__flap--bottom {
    bottom: 0;
    height: 54.3%;
    background-color: #ffffff;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    will-change: transform;
  }

}
.index {
  overflow: hidden;
}
body.index.loader {
  .section_1 {
    z-index: 30;
  }
  .iframe img {
    transform: scale(1.1);
  }
  h1 {
    color: #000000;
    span {
      color: #000000;
    }

  }

  .title_index {
    position: relative;
    z-index: 20;
  }
  .left_home {
    z-index: 20;
  }
  //.left_home {
  //  margin-right: 10px;
  //}
  //.nav_category {
  //  opacity: 0;
  //}

}
.nav_category {
  overflow: hidden;
}
.title_index {
  height: 41.6vh;
  width: 100%;
}
.index h1 {
  font-size: 125px;
  color: #ffffff;
  margin-top: 0;
  margin-right: 78px;
  letter-spacing: 2px;
  span {
    opacity: 0;
    padding: 0 20px;
  }
  span:nth-child(1) {
    margin-left: -140px;
  }

}
//.index h1:hover span{
//  -webkit-text-stroke-color: rgba(255,255,255,0.6);
//  -webkit-text-stroke-width: 1px;
//  color: rgba(255,255,255,0)!important;
//}
.right_home {
  width: calc(50vw);
  position: relative;
  overflow: hidden;
  .preloader_img {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    background-color: #000000;
    z-index: 1;
  }
  .iframe {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
.left_home {
  width: 48vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.bottom_section_1 {
  position: absolute;
  bottom: 30px;
  display: flex;
  justify-content: space-between;
  width: 94%;
  left: 0;
  .linc_social {
    width: 247px;
    ul{
      display: flex;
      justify-content: space-between;
      li {
        //margin: 0 20px 0 0;
      }
      .liner_hover:after {
        background:#ffffff
      }
      a {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        color: #ffffff;
        font-size: 14px;
      }
    }
  }
  .lang {
    a {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      color: #ffffff;
      font-size: 14px;
    }

    li {
      margin: 0 0 0 18px;
      position: relative;
    }
    li:not(:last-child):after {
      content: '/';
      position: absolute;
      right: -12px;
      color: #ffffff;
      top: 3px;
      transform: rotate(-4deg);
      font-size: 11px;
    }
    ul {
      display: flex;
    }
  }
}
.title_nav {
  width: 100%;
  position: relative;
  margin-top: -4px;
  .line_h1 {
    width: 0;
    top: 9px;
    height: 1px;
    background-color: #ffffff;
    position: absolute;
    right: 0;
    opacity: 0;
    transition: .3s;

  }
}
/*****************************/
.section_2 {
  padding-top: 15px;

  .about_img {
    margin-top: 75px;
    position: relative;

    width: 100%;
  }
}
.section_4 {
  .wrap.content_index {
    margin-bottom: 95px;
  }
}
//.section_5 {
//  padding-top: 77px;
//}
.nav_category {
  li:not(:nth-child(1)) {
    margin: 22px 0;
  }
  li:nth-child(1) {
    margin: 8px 0 22px 0;
    padding-top: 25px;
  }
  li {
    opacity: 0;
  }
  a {
    font-size: 14px;
    text-transform: capitalize;
    color: #FFFFFF;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }
  a.liner_hover:after {
    background-color: #ffffff;
  }
}
body.index {
  .wrap.content_index {
      max-width: 1060px;
    }
  .js-pointer span {
    background: #000000;
  }
  .js-pointer path {
    stroke:#000000;
  }
  .js-pointer.section path {
    stroke: #fff;
  }
  header {
    background-color: #000000;
    color: #ffffff;
    a {
      color: #ffffff;
    }
    ul li a:after {
      background: #ffffff;
    }
  }
  .index_about.right {
    display: flex;
    justify-content: flex-end;
  }
  .position {
    width: 530px;
  }
}

.product_wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 150px;
  .pro {
    position: relative;
    width: 25%;
    height: 652px;
    .pro_img {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    a {
      color: #ffffff;
      text-decoration: none;
    }
    h3 {
      font-size: 30px;
      font-weight: 400;
      opacity: 0;
    }
    p {
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      margin-top: 15px;
      line-height: 24px;
      opacity: 0;
    }
    .div_pro {
      position: absolute;
      padding: 44px;
      opacity: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%) scale(0.9);
      width: 86%;
      height: 86%;
      transition: opacity .1s ease-out, transform .2s ease-in-out;
    }
    .read-more {
      position: absolute;
      bottom: 60px;
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      opacity: 0;
    }
    .read-more:after {
      content: '';
      height: 1px;
      width: 100%;
      background-color: #ffffff;
      position: absolute;
      bottom: -12px;
      left: 0;
    }
  }
  .pro:hover .div_pro{
    opacity: 1;
    background-color: #000000;
    transform: translate(-50%,-50%) scale(1.0);
    p,h3,.read-more {
      opacity: 1;
    }
  }
}
@media only screen and (min-width: 1025px) {
  .product_wrap {
    .pro {
      height: 33.958333333333336vw;

      h3 {
        font-size: 1.5625vw;
      }
      p {
        font-size: 0.7291666666666666vw;
        margin-top: 0.78125vw;
        line-height: 1.25vw;
      }
      .div_pro {
        padding: 2.2916666666666665vw;
      }
      .read-more {
        bottom: 3.125vw;
        font-size: 0.625vw;
      }
    }
  }
}

img {
  width: 100%;
}
.index_about {
  margin-top: 74px;
  h2 {
    font-family: 'bodoni';
    font-size: 30px;
    color: #131313;
    font-weight: 400;
    margin-bottom: 14px;
  }
  data {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #C4C4C4;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #343434;
    margin-top: 14px;
    line-height: 24px;
  }
  .read-more {
    margin-top: 33px;
    position: relative;
    display: inline-block;
    a {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      font-size: 12px;
      color: #000000;
    }
  }
  .read-more:after {
    content: '';
    position: absolute;
    bottom: -17px;
    width: 100%;
    height: 1px;
    background-color: #000000;
  }
}


.com_article {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1250px;
  article {
    width: 407px;
    height: 555px;
    justify-content: center;
    align-items: center;
    a {
      padding: 0 68px 25px;
    }
    .catalog_img {
      width: 205px;
      height: 295px;
      margin-top: 36px;
    }
    .buttons-wrap {
      margin-bottom: 22px;
      margin-top: 20px;
      p:before {
        bottom: -12px;
      }
    }
  }
  article:nth-child(1) {
    position: relative;
    //left: -50px;
  }
  article:nth-child(3) {
    position: relative;
    //right: -65px;
  }
}
/////////////////////////
.news_wrap {
  display: flex;
  margin-top: 129px;
  //width: max-content;
  .slick-list {
    padding: 50px !important;
  }
}
.news_article {
  width: 356px;
  height: 485px;
  position: relative;
  transition: width .5s;

  .news_img {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: .3s ease-in-out;
    left: 0;
    top: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 1;
      transition: .3s ;
    }
  }
  h3 {
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    position: relative;
    opacity: 0;
    transition: .0s ease-in-out;
  }
  p {
    opacity: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    position: relative;
    color: #ffffff;
    transition: .0s ease-in-out;
    font-size: 14px;
    margin-top: 16px;
    line-height: 24px;
  }
  .news_pro {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 7px 33px 7px 15px;
  }
  .news_pro:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all .3s ease-in-out, z-index .0s ;
    background-color: #131313;
    z-index: -1;
  }

}
.liner_hover {
  display: inline-block;
  position: relative;
}
.liner_hover:after {
  content: '';
  position: absolute;
  background: #000000;
  height: 1px;
  width: 0;
  left: 0;
  top: 20px;
  transition: all .3s;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  opacity: 1;
  z-index: 9;
  -webkit-backface-visibility: hidden;
}
.liner_hover:hover:after {
  opacity: 1;
  transition-delay: .2s;
  width: 100%;
}


.news_article:hover {
  .news_pro {
    z-index: 1;
    h3 , p{
      z-index: 1;
      opacity: 1;
      transition: .3s ease-in-out;
    }
  }
  img {
    //opacity: 0;
    z-index: 1;
  }
  .news_img {
    left: -28px;
    top: -43px;
    width: calc(100% + 51px);
    height: calc(100% + 70px);
  }
  .news_pro:before {
    background-color: #131313;
    left: -28px;
    z-index: 1;
    top: -43px;
    width: calc(100% + 51px);
    height: calc(100% + 70px);
    transition: all .3s ease-in-out, z-index .0s ;
  }
}
//.news_article:hover {
//  width: 400px;
//}
///////////////////////////
.contact_us {
  display: flex;
  padding-top: 75px;
  #map {
    width: 521px;
    height: 312px;
    //background-color: #cccccc;
  }
  .index_about {
    margin-top: 0;
  }
}
/////////////////////////

.section_6 {
  padding-top: 73px;
  padding-bottom: 240px;
  .adress_us {
    margin-top: 20px;
    p {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #343434;
      margin-top: 0;
    }
  }
  .email_us {
    margin-top: 48px;
    display: flex;
    align-items: center;
    color: #343434;
    p {
      margin-top: 0;
      transition: .5s;
      padding-right: 8px;
    }
    a {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      color: #343434;
      font-size: 14px;
      transition: .5s;
    }
    a:hover {
      color: #000000;
    }
  }
  .tell_us {
    margin-top: 48px;
    p {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      margin-top: 0;
      padding-right: 10px;
      transition: .5s;
      font-size: 14px;
      margin-bottom: 3px;
    }
    a {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      color: #343434;
      font-size: 14px;
      transition: .5s;
    }
    a:hover {
      color: #000000;
    }
  }
}



///////////////////////
@media only  screen and (max-width: 1600px)  and (min-width: 1025px) {
  body.index {
    .wrap.content_index {
      max-width: 66.25vw;
      padding: 0;
    }

    .position {
      width: 33.125vw;
    }
  }
  .section_2 .about_img {
    margin-top: 3.90625vw;
  }
  .index_about .read-more:after {
    bottom: -0.8854166666666666vw;
  }
  .index_about {
    margin-top: 4.625vw;
    h2 {
      font-size: 1.875vw;
      margin-bottom: 0.8750000000000001vw;
    }
    data {
      font-size: 0.75vw;
    }
    p {
      font-size: 0.8750000000000001vw;
      margin-top: 0.8750000000000001vw;
      line-height: 1.5vw;
    }
    .read-more {
      margin-top: 2.0625vw;
      a {
        font-size: 0.75vw;
      }
    }


  }
  .com_article article {
    width: 25.4375vw;
    height: 34.6875vw;
    a {
      padding: 0 4.25vw 1.5625vw;
    }
    h3 {
      font-size: 1.575vw;
    }
    .top_article {
      font-size: 0.8125vw;
      margin-top: 0.625vw;
      margin-bottom: 1.5vw;
      p {
        margin-right: 1.25vw;
      }
    }
    .catalog_img {
      width: 12.812499999999998vw;
      height: 18.4375vw;
      margin-top: 2.25vw;
    }
    .buttons-wrap {
      margin-bottom: 1.375vw;
      margin-top: 1.25vw;
    }
    //.hover {
    //  top: calc(50% + 12px);
    //  width: 165px;
    //  height: 240px;
    //}

  }
  .com_article {
    max-width: 78.125vw;
  }
  article .hover {
    top: calc(50% + 0.75vw);
    left: 50%;
    width: 10.3125vw;
    height: 15vw;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  article:hover .hover {
    width: 100%;
    height: 100%;
    opacity: 1;
    top: calc(50% + 0px);
  }
  .news_wrap {
    margin-top: 8.0625vw;
    .slick-list {
      padding: 3.125vw !important;
    }
  }
  .news_article {
    //width: 22.25vw;
    width: 18.541666666666668vw;
    //height: 30.312499999999996vw;
    height: 25.260416666666668vw;
    .news_pro {
      //padding: 0.43750000000000006vw 2.0625vw 0.43750000000000006vw 0.9375vw;
      padding: 0.3645833333333333vw 1.7187500000000002vw 0.3645833333333333vw 0.78125vw;
    }
    h3 {
      //font-size: 1.875vw;
      font-size: 1.5625vw;
    }
    p {
      //font-size: 0.8750000000000001vw;
      font-size: 0.7291666666666666vw;
      //margin-top: 1vw;
      margin-top: 0.8333333333333334vw;
      //line-height: 1.5vw;
      line-height: 1.25vw;
    }
  }
  .news_article:hover .news_img {
    //left: -1.7500000000000002vw;
    left: -1.4583333333333333vw;
    //top: -2.6875vw;
    top: -2.2395833333333335vw;
    //width: calc(100% + 3.1875vw);
    width: calc(100% + 2.65625vw);
    //height: calc(100% + 4.375vw);
    height: calc(100% + 3.6458333333333335vw);
  }
  .news_article:hover .news_pro:before {
    //left: -1.7500000000000002vw;
    left: -1.4583333333333333vw;
    //top: -2.6875vw;
    top: -2.2395833333333335vw;
    width: calc(100% + 2.65625vw);
    //height: calc(100% + 4.375vw);
    height: calc(100% + 3.6458333333333335vw);
  }
  .section_6 {
    padding-top: 4.5625vw;
    padding-bottom: 15vw;
  }
  .contact_us {
    padding-top: 4.6875vw;
  }
  .section_6 .adress_us {
    margin-top: 1.25vw;
  }
  .contact_us #map {
    width: 32.5625vw;
    height: 19.5vw;
  }
  .section_6 .adress_us p {
    font-size: 0.8750000000000001vw;
  }
  .section_6 .email_us a {
    font-size: 0.8750000000000001vw;
  }
  .section_6 .email_us {
    margin-top: 3vw;
  }
  .section_6 .tell_us {
    margin-top: 3vw;
  }
  .section_6 .tell_us p {
    padding-right: 0.625vw;
    font-size: 0.8750000000000001vw;
    margin-bottom: 0.1875vw;
  }
  .section_6 .tell_us a {
    font-size: 0.8750000000000001vw;
  }
  footer {
    height: 11.25vw;
  }
  .footer__columns {
    margin-top: -1.8124999999999998vw;
  }
  footer .footer_logo p {
    font-size: 1.375vw;
    line-height: 2.75vw;
  }
  .footer_address {
    margin-bottom: 1.7500000000000002vw;
  }
  footer p {
    font-size: 0.8125vw;
    line-height: 1.0625vw;
  }



  /******************************/
  .index h1 {
    font-size:6.510416666666667vw;
    margin-right: 4.0625vw;
    letter-spacing: 0.10416666666666667vw;
  }
  .index h1 span {
    padding: 0 1.0416666666666665vw;
  }
  .index h1 span:nth-child(1) {
    margin-left: -7.291666666666667vw;
  }
  .one_section .header nav ul li {
    margin-right: 6.489583vw;
  }
  .one_section .header nav ul li:last-child {
    margin-right: 3.0729166666666665vw;
  }
  .one_section {
    header {
      .wrap {
        //max-width: 1366px;
        padding: 0 3.125vw;
      }
    }
  }
  .nav_category a {
    font-size: 0.8750000000000001vw;
  }
  .bottom_section_1 .linc_social {
    width: 15.437500000000002vw;
  }
  .nav_category li:not(:nth-child(1)) {
    margin: 1.375vw 0;
  }
  .nav_category li:nth-child(1) {
    margin: 0.5vw 0 1.375vw 0;
    padding-top: 1.5625vw;
  }
  .bottom_section_1 {
    bottom: 1.875vw;
  }
  .bottom_section_1 .linc_social ul a {
    font-size: 0.8750000000000001vw;
  }
  .bottom_section_1 .lang li {
    margin: 0 0 0 1.125vw;
  }
  .bottom_section_1 .lang a {
    font-size: 0.8750000000000001vw;
  }
  .title_nav .line_h1 {
    top: 0.5625vw;
  }
  .bottom_section_1 .lang li:not(:last-child):after {
    right: -0.75vw;
    top: 0.1875vw;
    font-size: 0.6875vw;
  }
}